import { clsx } from 'clsx';

export const NewLabel = () => {
  return (
    <span
      className={clsx(
        'tw-h-4 tw-px-1.5 tw-pb-px tw-inline-flex tw-items-center',
        'tw-bg-primary-500 tw-text-white tw-text-ui10 tw-font-bold tw-rounded-full'
      )}
    >
      NEW
    </span>
  );
};
