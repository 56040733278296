import type { NotificationHeadlineCardListItemProps } from './type';

import { AnchorLink, HeadlineCard, NewLabel } from '@/components/ui';
import { ROUTE, DATE_FORMAT } from '@/constants';
import { formatDate, isWithinPastDays } from '@/utils/date';

export const NotificationHeadlineCardListItem = ({
  item,
}: NotificationHeadlineCardListItemProps) => {
  return (
    <AnchorLink href={ROUTE.notificationDetail(item.id ?? -1)}>
      <HeadlineCard
        title={item.title ?? ''}
        publishedAt={formatDate(item.publishedAt, DATE_FORMAT.yyyyMMdd) ?? ''}
        label={isWithinPastDays(item.publishedAt, 7) ? <NewLabel /> : undefined}
      />
    </AnchorLink>
  );
};
