import { clsx } from 'clsx';
import { H } from 'react-headings';

import type { HeadlineCardProps } from './type';

export const HeadlineCard = ({
  title,
  publishedAt,
  label,
}: HeadlineCardProps) => {
  return (
    <article
      className={clsx(
        'tw-pt-2 tw-pb-2.5 tw-border-b tw-border-gray-200 tw-space-y-1',
        'md:tw-flex md:tw-flex-row-reverse md:tw-py-3 md:tw-gap-x-3'
      )}
    >
      <H
        className={clsx(
          'tw-text-body14 tw-line-clamp-2',
          'md:tw-flex-1 md:tw-line-clamp-1'
        )}
      >
        {title}
      </H>
      <div
        className={clsx(
          'tw-flex tw-items-center tw-gap-x-1',
          'md:tw-w-28 md:tw-gap-x-2'
        )}
      >
        <time
          className={clsx('tw-text-ui10 tw-text-gray-600', 'md:tw-text-ui12')}
        >
          {publishedAt}
        </time>
        {label !== undefined && label}
      </div>
    </article>
  );
};
