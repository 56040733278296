import type { HeadingProps } from './type';

export const titleType: Record<NonNullable<HeadingProps['type']>, string> = {
  blockTitle: '',
  subBlockTitle: 'tw-text-head20',
  sectionTitle:
    'tw-flex tw-items-center tw-text-head20 md:tw-text-head24 after:tw-content-[""] after:tw-grow after:tw-ml-4 after:tw-h-[1px] after:tw-bg-gray-400',
  withLeftBorder:
    'tw-pt-1.5 tw-pb-2 tw-pl-2 tw-text-head20 tw-border-l-4 tw-border-primary-500 md:tw-pl-3 md:tw-text-head32 md:tw-border-l-[6px]',
};
