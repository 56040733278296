import { clsx } from 'clsx';
import { H } from 'react-headings';

import type { HeadingProps } from './type';

import { titleType } from './const';

export const Heading = ({
  type = 'blockTitle',
  children,
}: HeadingProps): JSX.Element => {
  return (
    <H
      className={clsx(
        'tw-text-black tw-font-bold tw-text-head20 md:tw-text-head24 tw-break-all',
        titleType[type]
      )}
    >
      {children}
    </H>
  );
};
