import { filter } from 'graphql-anywhere';

import type { NotificationHeadlineCardListProps } from './type';

import { NotificationHeadlineCardListItemFragmentDoc } from '@/graphql/generated';

import { NotificationHeadlineCardListItem } from './NotificationHeadlineCardListItem';

export const NotificationHeadlineCardList = ({
  items,
}: NotificationHeadlineCardListProps) => {
  return (
    <ul>
      {items.map((item) => (
        <li key={item.id}>
          <NotificationHeadlineCardListItem
            item={filter(NotificationHeadlineCardListItemFragmentDoc, item)}
          />
        </li>
      ))}
    </ul>
  );
};
